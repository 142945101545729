.futureAncestorsContainer {
    background-color: white; /* Set background color to white */
}

.speaker{
    font-size: 36px;
    color: #f5f5f5;
    font-weight: bold;
}

.topic{
    font-size: 20px;
    color: #f5f5f5;
}

