.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px
}


.textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem;
    color: rgb(204, 201, 204);
}

.header {
    font-size: 6rem;
    margin-bottom: 1rem;
    font-weight: bold;
}

.paragraph {
    font-size: 1.3rem; /* Adjust the font size as needed */
    line-height: 1.6;
    color: rgb(204, 201, 204);
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


.imageWrapper {
    margin: 1rem;
    justify-content: flex-end;
    display: flex;
}

.image {
    max-width: 100%;
    height: auto;

    
}
.navList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0); /* Optional: To add a semi-transparent background */
    width: 100%;
    z-index: 1000;
}

.navList li {
    margin: 0 0px;
}

a {
    color: rgb(178, 211, 253);
    text-decoration: none; /* optional: removes the underline */
}

a:hover {
    color: rgb(95, 182, 253); /* optional: changes color on hover */
}


.navList a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    padding: 10px;
}

.navList a:hover {
    text-decoration: underline;
}
@media (min-width: 768px) {
    .container {
        flex-direction: row;
        align-items: flex-start;
    }

    .textContainer {
        flex: 1;
        align-items: flex-start;
    }

    .imageWrapper {
        flex: 1;
        margin-left: 1rem;
    }


    .textContainer {
        align-items: flex-start;
    }

    .image {
        max-width: 80%;
    }
}
