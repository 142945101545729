/* styles.css */
html {
    scroll-behavior: smooth;
}

.main {
    /* padding: 10px; */
    background-color: #0f0d0e;
    padding-top: 50px;
}


body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    color: rgb(19, 19, 19);
    background-color: #0f0d0e;
}

.home {
    /* margin: 20px; */
    padding: 20px;
    background-color: #0f0d0e
}

h1 {
    font-size: 36px;
    color: #bbdaf6;
    border-bottom: 2px solid #0557a4;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-family: 'Courier New', Courier, monospace;
}

h2 {
    font-size: 36px;
    color: #dfdfdf;
    stroke: rgba(0, 0, 0, 1.0);
    stroke-width: 50%;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

h3 {
    font-size: 24px;
    color: #dfdfdf;
}
p {
    font-size: 18px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #dfdfdf;
}



button {
    align-self: start;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #006e7f;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

.section {
    margin-bottom: 40px;
}

.section h2 {
    font-size: 28px;
    color: #0557a4;
    margin-bottom: 20px;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
}



li {
    position: relative;
    /* background-color: #ACE2E1; */
    /* border-radius: 8px; */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
}

li::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0.8;
    z-index: -1;
}

li:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0); /* Optional: To add a semi-transparent background */
    width: 100%;
    z-index: 1000;
}

.navList li {
    margin: 0 0px;
}

.navList a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    padding: 10px;
}

.navList a:hover {
    text-decoration: underline;
}

.projectList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
}

.projectItem {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
    color: white; /* Ensure text is readable */
    background-size: cover ;
    background-position: center;
    color: 'white';
    padding: '20px';
    background-color: rgba(0, 0, 0, 0.5);
}

.projectItem::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust the opacity as needed */
    z-index: -1;
}

.projectItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
.affiliations {
    position: relative;
    /* border-radius: 8px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
    cursor: pointer;
    align-items: center;
}
.affiliations::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0.8;
    z-index: -1;
}

.affiliations:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.affiliations-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust to fit 6 columns */
    gap: 20px;
    align-items: center;
}

.affiliations img {
    min-width: 60%;
    max-width: 100%;
    height: auto; /* Maintains aspect ratio */

    /* max-height: 2; Adjust as needed */
    object-fit: cover; /* Ensures the image covers the specified dimensions */
}





/* Responsive styles */
@media (max-width: 768px) {
    h1 {
        font-size: 28px;
    }

    ul {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    li {
        padding: 15px;
    }
    

    button {
        padding: 8px 16px;
        font-size: 14px;
    }
}
