.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem;
    color: rgb(204, 201, 204);
}

.header {
    font-size: 6rem;
    margin-bottom: 1rem;
    font-weight: bold;
}

.paragraph {
    font-size: 1.3rem; /* Adjust the font size as needed */
    line-height: 1.6;
    color: rgb(204, 201, 204);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


.imageWrapper {
    margin: 1rem;
    justify-content: flex-end;
    display: flex;
}

.image {
    max-width: 100%;
    height: auto;
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
        align-items: flex-start;
    }

    .textContainer {
        flex: 1;
        align-items: flex-start;
    }

    .imageWrapper {
        flex: 1;
        margin-left: 1rem;
    }


    .textContainer {
        align-items: flex-start;
    }

    .image {
        max-width: 80%;
    }
}
